import { STREAM_KEYS } from 'lib/liveVideo';

import { LiveVideoEmbedNewsNowLogo } from './NewsNow';
import { LiveVideoEmbedTodayAllDayLogo } from './TodayAllDay';
import { LiveVideoEmbedMSNBCTVELogo } from './MSNBCTVE';
import { LiveVideoEmbedNoticiasAhoraLogo } from './NoticiasAhora';
import { LiveVideoEmbedSportsLogo } from './SportsNow';

const STREAM_KEY_LOGO_MAP = {
  [STREAM_KEYS.NEWS_NOW]: LiveVideoEmbedNewsNowLogo,
  [STREAM_KEYS.MSNBC_TVE]: LiveVideoEmbedMSNBCTVELogo,
  [STREAM_KEYS.TODAY_ALL_DAY]: LiveVideoEmbedTodayAllDayLogo,
  [STREAM_KEYS.NOTICIAS_AHORA]: LiveVideoEmbedNoticiasAhoraLogo,
  [STREAM_KEYS.SPORTS]: LiveVideoEmbedSportsLogo,
};

/**
 *
 * @param {STREAM_KEYS} key
 */
export function getLogoForStreamKey(key) {
  return STREAM_KEY_LOGO_MAP[key] || null;
}

export {
  LiveVideoEmbedNewsNowLogo,
  LiveVideoEmbedTodayAllDayLogo,
  LiveVideoEmbedMSNBCTVELogo,
  LiveVideoEmbedNoticiasAhoraLogo,
  LiveVideoEmbedSportsLogo,
};
